import React, {useEffect, useState} from 'react';

const InGameAdminGuide = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const scrollToSection = (index) => {
        const section = document.getElementById(`section-${index}`);
        if (section) {
            window.location.hash = `#${guideParts[index].toLowerCase().replace(/\s+/g, '-')}`;
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const guideParts = [
        "Tasks",
        "Being part of the team",
        "Activity",
        "Taking time off",
        "Mentorship",
        "Handling support tickets",
        "Handling platoon tickets",
        "Taking an action on a player",
        "Punishment order",
        "Language punishment order",
        "Punishment exceptions",
        "Commands",
        "Useful tools"
    ];

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.substring(1); // Remove '#' from hash
            const index = guideParts.findIndex(part => part.toLowerCase().replace(/\s+/g, '-') === hash);
            if (index !== -1) {
                scrollToSection(index);
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        // Scroll to the correct section if there is a hash in the URL
        const hash = window.location.hash.substring(1);
        const index = guideParts.findIndex(part => part.toLowerCase().replace(/\s+/g, '-') === hash);
        if (index !== -1) {
            scrollToSection(index);
        }

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <div className={"bg-gradient-to-b from-zinc-900 to-zinc-800 min-h-screen p-4"}>
            <div className={"max-w-screen-lg mx-auto p-4 text-center"}>
                <div className="mb-2">
                    <button className={`w-full text-left p-4 bg-zinc-700 0 flex justify-between items-center ${isOpen ? 'rounded-t-xl' : 'rounded-xl'}`} onClick={toggleAccordion}>
                        <h1 className={"text-center text-2xl lg:text-3xl lg:text-left text-amber-600 font-cubano"}>Sections of the in-game admin guide</h1>
                    </button>
                    {isOpen && (
                        <div className="p-4 bg-zinc-800">
                            <ul className="list-disc list-inside text-left">
                                {guideParts.map((value, index) => (
                                    <li className="text-white text-lg font-semibold cursor-pointer" onClick={() => scrollToSection(index)}>{value}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className={"max-w-screen-lg mx-auto p-4 text-left"}>
                <section id={"section-0"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Tasks</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>3 main tasks that revolve around being an admin on the BF4C
                                    servers: <p className="text-red-500 inline">Enforcing rules</p>, <p
                                        className="text-red-500 inline">Handling Discord tickets</p>, and <p
                                        className="text-red-500 inline">Actively engaging with the community and the
                                        rest of the team</p>.
                                </li>
                                <li className={"mb-4"}>Thanks to willing admins we can follow the vision and purpose of
                                    BF4C. That is providing an enjoyable BF4 experience for everyone. Let it be players
                                    or our community members.
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-1"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Being part of the team</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>Effective, clear, concise communication is essential.</li>
                                <li className={"mb-4"}>Be familiar with the <a href="/servers"
                                                                               className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600 transition-all">rules</a>, <a
                                    href="/banpolicy"
                                    className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600 transition-all">ban
                                    policy</a>, and admin guide, and when
                                    in doubt, ask other team members for assistance.
                                </li>
                                <li className={"mb-4"}>The team leader has the final say in all of the team's
                                    decisions.
                                </li>
                                <li className={"mb-4"}>The ability to be unbiased is very crucial. Admins should not let
                                    personal feelings or relationships influence their decisions.
                                </li>
                                <li className={"mb-4"}>Approach duties with a positive attitude and a willingness to
                                    assist players.
                                </li>
                                <li className={"mb-4"}>Be prepared to handle any situation that arises, including player
                                    reports, ban appeals, and admin abuse reports.
                                </li>
                                <li className={"mb-4"}>Work as a team, supporting other staff members and
                                    collaborating to resolve issues.
                                </li>
                                <li className={"mb-4"}>Show solidarity and stand by other staff members, addressing any
                                    disagreements with respect and a clear objective of resolution.
                                </li>
                                <li className={"mb-4"}>
                                    <p className={"text-red-500 inline"}>Use #staff-chat channel on Discord for only
                                        serious and staff-related matters! </p>This is important
                                    to prevent clogging of the chat and information.
                                </li>
                            </ol>
                        </p>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Voting and team decision
                        making</h3>
                    <p className={"text-base text-gray-100 font-sofia-pro"}>
                        <ol className={"list-disc ml-6 mt-4"}>
                            <li className={"mb-4"}>For more serious matters, Google Forms are used for concluding a
                                vote. The nature of questions often requires individual to explain their reasoning
                                behind their opinion or stand.
                            </li>
                            <li className={"mb-4"}>In some cases anonymous voting might be concluded. The team is always
                                made aware if that's the case.
                            </li>
                            <li className={"mb-4"}>For the less serious matters, a simple yes or no vote using reactions
                                is done through Discord.
                            </li>
                            <li className={"mb-4"}>Each voting process has its own time limit and only active admins are
                                included in the vote process.
                            </li>
                            <li className={"mb-4 text-red-500"}>In order to conclude the vote, at least 85% attendance
                                from all the active admins is required.
                            </li>
                            <li className={"mb-4"}>The result of the vote is binding and cannot be changed thereafter.
                            </li>
                            <li className={"mb-4"}>During the vote process, each individual <p
                                className="text-red-500 inline">must</p> remain unbiased as outlined above.
                            </li>
                        </ol>
                    </p>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Admin referral</h3>
                    <p className={"text-base text-gray-100 font-sofia-pro"}>
                        <ol className={"list-disc ml-6 mt-4"}>
                            <li className={"mb-4"}>Active admins can refer disciplined and active people from the
                                community or in-game servers.
                            </li>
                            <li className={"mb-4"}>A special channel #staff-referral channel on Discord can be used for
                                suggesting new admin and discussion.
                            </li>
                        </ol>
                    </p>
                </section>

                <section id={"section-2"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Activity</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4 text-red-500"}>Actively playing on BF4C servers is a requirement
                                    for all
                                    the admins.
                                </li>
                                <li className={"mb-4"}>Admins are expected to be <p
                                    className="text-red-500 inline">active on the BF4C Discord server</p>. This
                                    includes both text, and voice channels, and handling support tickets.
                                </li>
                            </ol>
                        </p>

                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Becoming inactive</h3>
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>If an admin becomes inactive, they loose the ability to ban and
                                    unban players, cannot mentor, and cannot vote in staff voting/admin applications.
                                </li>
                                <li className={"mb-4"}>An admin is considered inactive if they have not played on the
                                    BF4C servers and have not been active on the Discord server (text, voice channels
                                    and tickets) for 30 days.
                                </li>
                                <li className={"mb-4"}>In-game activity is valued more than Discord activity. While it's
                                    important to be active on Discord, the main focus should be on the BF4C servers.
                                </li>
                                <li className={"mb-4"}>If an admin is inactive for 30 days, they will be demoted to the
                                    Inactive Admin role.
                                </li>
                                <li className={"mb-4"}>Inactive admins can regain their admin status by becoming active
                                    again.
                                </li>
                                <li className={"mb-4"}>If an admin is inactive for 90 days, they will be unconditionally
                                    removed from the admin team.
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-3"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Taking time off</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>Admins are <p className="text-red-500 inline">allowed to take
                                    time off when needed</p>. This can be due
                                    to personal reasons, lack of interest in the game, or other factors.
                                </li>
                                <li className={"mb-4"}>
                                    During the time off, the admin has their active status <p
                                    className="text-red-500 inline">kept </p>
                                    while they <p className="text-red-500 inline">are not expected</p> to perform their
                                    duties.
                                </li>
                                <li className={"mb-4"}>The maximum time off duration is 90 days. If the admin is unable
                                    to return after 90 days, they will be given the Inactive Admin role.
                                </li>
                                <li className={"mb-4"}>In special cases, the time off duration <p
                                    className="text-red-500 inline">can be extended</p>, but the
                                    admin must provide a valid reason for the extension.
                                </li>
                                <li className={"mb-4"}>Time off should not be taken lightly. Admins should consider the
                                    impact of their absence on the team and the community.
                                </li>
                                <li className={"mb-4"}>It's expected for the admin to <p
                                    className="text-red-500 inline">return to their duties</p> after the
                                    time off period has ended.
                                </li>
                                <li className={"mb-4"}>To take time off, write a message in the #staff-chat
                                    channel on Discord, explaining the reason for the absence and the expected duration.
                                </li>
                                <li className={"mb-4"}>A special calendar is used to track time off requests. You can
                                    find it in the #staff-information channel.
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-4"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Mentorship</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>Each new trial admin is assigned a mentor to guide them through
                                    the process of understanding the what it means to be an admin on the BF4C servers.
                                </li>
                                <li className={"mb-4"}>The trial admin can choose their mentor, but the mentor has an
                                    option to accept or decline the request.
                                </li>
                                <li className={"mb-4"}>Only active admins that have been part of the team for at least 3
                                    months can be mentors.
                                </li>
                                <li className={"mb-4"}>During the 3-week trial period, there have to be at least <p
                                    className={"text-red-500 inline"}>3 mentorship sessions for 2 hours minimum.</p>
                                </li>
                                <li className={"mb-4"}>The mentor is expected to provide guidance, answer questions, and
                                    help the trial admin understand the rules and guidelines.
                                </li>
                                <li className={"mb-4 text-red-500"}>The mentorship sessions must be held exclusively in
                                    a voice channel and both the mentor and the trial admin should be playing on the
                                    BF4C servers!
                                </li>
                                <li className={"mb-4"}>Both the mentor and the trial admin have to schedule the sessions
                                    in advance.
                                </li>
                                <li className={"mb-4"}>The mentor is expected to provide feedback to the trial admin
                                    after the trial period has ended.
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-5"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Handling support tickets</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>Admins are expected to handle tickets on time.</li>
                                <li className={"mb-4"}>When first responding to a ticket, the admin should acknowledge
                                    the player's issue and work towards a resolution.
                                </li>
                                <li className={"mb-4"}>The first response must also include a greeting and the member
                                    who
                                    opened the ticket must be <p className="text-red-500 inline">pinged/tagged to speed
                                        up the process</p>.
                                </li>
                                <li className={"mb-4"}>The member should be pinged/tagged if they are not responding to
                                    the ticket on time too.
                                </li>
                                <li className={"mb-4"}>It's a complete must to respond to an unanswered ticket if the
                                    admin
                                    is online and available.
                                </li>
                                <li className={"mb-4"}>Another admin can take over the ticket with <p
                                    className="text-red-500 inline">explicit permission </p>
                                    from the original admin.
                                </li>
                                <li className={"mb-4"}>Taking over the ticket is also possible if the original admin is
                                    not actively working on it.
                                </li>
                                <li className={"mb-4"}>There is an option for the Discord members to request an in-game
                                    ping whitelist through the tickets. If they have high ping, the admin should
                                    whitelist them.
                                </li>
                                <li className={"mb-4 text-red-500"}>Admin abuse tickets absolutely cannot be handled by
                                    the admin who
                                    is accused of the abuse. The accused admin can defend themselves in a staff chat
                                    however.
                                </li>
                                <li className={"mb-4"}>If the member is hostile or uncooperative, a ticket can be closed
                                    after a warning.
                                </li>
                                <li className={"mb-4"}>Ticket can be closed after 3 days of inactivity from the member's
                                    side.
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-6"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Handling platoon tickets</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>Admins are expected to handle platoon tickets on time.
                                </li>
                                <li className={"mb-4"}>If the member has not applied to the platoon, the admin should
                                    tell them to do so by greeting them and tagging them.
                                </li>
                                <li className={"mb-4"}>Only members who finish the last step can be granted the
                                    benefits. If the member fails to do so, they can be removed from the platoon.
                                </li>
                                <li className={"mb-4"}>Platoon tickets are a straightforward process. See below.</li>

                                <p className={"text-base text-gray-300 font-sofia-pro"}>
                                    <ol className={"list-decimal ml-6 mt-4"}>
                                        <li className={"mb-4"}>Accept the member through Battlelog by using the nickname
                                            provided in the ticket.
                                        </li>
                                        <li className={"mb-4"}>Message them they have been accepted and can finish the
                                            last
                                            step.
                                        </li>
                                        <li className={"mb-4"}>Check their Battlelog profile to see if they have the
                                            [BF4C] tag.
                                        </li>
                                        <li className={"mb-4"}>If they have the tag, use the /platoon accept and /vip
                                            activate slash commands to grant them the benefits.
                                        </li>
                                    </ol>
                                </p>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-7"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Taking an action on a
                        player</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>All the commands that the admin executes are considered as
                                    actions.
                                </li>
                                <li className={"mb-4"}><p className="inline text-red-500 font-bold">All the actions are
                                    logged!</p> All the actions are public and can be seen on the BF4C Discord server in
                                    the #action-log channel.
                                </li>
                                <li className={"mb-4"}>All the actions taken must be within the defined rules and
                                    guidelines!
                                </li>
                                <li className={"mb-4"}>
                                    If the player violates rule(s) multiple times, the admin must take an action each
                                    time a violation happens.
                                    Bulk actions (player violates rule(s) multiple times without admin actions between)
                                    are <p className="inline text-red-500">not
                                    allowed!</p>
                                </li>
                                <li className={"mb-4"}>
                                    Players can <p className="inline text-red-500">only</p> be unbanned if the ban
                                    policy
                                    allows them to appeal their ban. Ban appeals
                                    are <p className="inline text-red-500">exclusively handled through the
                                    Discord ticket system!</p>
                                </li>
                                <li className={"mb-4"}>
                                    While our policies and rules continue to adapt, the option to reverse or remove past
                                    actions, including bans is <p className="inline text-red-500">not
                                    possible.</p>
                                </li>
                                <li className={"mb-4"}>If banning for cheating, the admin must have video
                                    evidence of the player cheating. The footage must be long enough to show the player
                                    cheating.
                                </li>
                                <li className={"mb-4"}>All the banned players for cheating must be reported to <a
                                    href="https://bf4db.com"
                                    className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600">BF4DB</a>.
                                </li>
                            </ol>
                        </p>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Executing commands</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>Commands can be executed in-game or through the BFACP.</li>
                                <li className={"mb-4 text-red-500"}>The admin must always follow the standard punishment
                                    order
                                    provided below, with only a few exceptions detailed later in the guide.
                                </li>
                            </ol>
                        </p>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Admin Panel (BFACP)</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>The BFACP is a powerful web application that allows the admin to
                                    execute commands without joining the server.
                                </li>
                                <li className={"mb-4"}>It also provides detailed information about players and
                                    servers.
                                </li>
                                <li className={"mb-4"}>Due to its complexity, it can be confusing to use at first, but
                                    with time and practice, it becomes a powerful tool for the admin to use.
                                </li>
                                <li className={"mb-4"}>If confused or in doubt about how to use it, ask other team
                                    members for
                                    assistance.
                                </li>
                            </ol>
                        </p>

                        <img
                            src={require("../Images/AdminPanel.png")}
                            alt={"AdminPanel"}
                            className={"w-full h-auto rounded-lg"}
                        />

                        <img
                            src={require("../Images/AdminPanelProfile.png")}
                            alt={"AdminPanel"}
                            className={"w-full h-auto rounded-lg mt-12"}
                        />
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Infraction system</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>The infraction system is used to track player rule violations.
                                    It's optional to use, but it's recommended.
                                </li>
                                <li className={"mb-4"}>Each time a player is punished, their infraction points
                                    increase.
                                </li>
                                <li className={"mb-4"}>Each time a player is forgiven, their infraction points
                                    decrease.
                                </li>
                                <li className={"mb-4"}>The infraction system follows the standard punishment order.</li>
                                <li className={"mb-4"}>You can see the player's infraction points including history in
                                    the BFACP.
                                </li>
                            </ol>
                        </p>

                        <img
                            src={require("../Images/Infractions.png")}
                            alt={"Infractions"}
                            className={"w-full h-auto rounded-lg"}
                        />
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Predefined messages</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>Predefined messages can be used instead of reason with each
                                    command.
                                </li>
                                <li className={"mb-4"}>Each predefined message is a number that corresponds to one
                                    server rule depending on the order of the rules.
                                </li>
                                <li className={"mb-4"}>Usage: <p className="text-red-500 inline">/punish TheTomikIT
                                    11</p>. The player will be punished for the rule number 11.
                                </li>

                            </ol>
                        </p>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Report system</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>The report system allows players to report rule-breakers.</li>
                                <li className={"mb-4"}>The reports can be seen in the #reports channel on Discord or in
                                    the BFACP.
                                </li>
                                <li className={"mb-4"}>Reports can be accepted, denied, or ignored.</li>
                            </ol>
                        </p>

                        <div className="flex flex-row justify-between">
                            <img
                                alt={"ReportSystem"}
                                src={require("../Images/Reports1.png")}
                                className={"w-1/3 h-1/3 rounded-lg"}
                            />

                            <img
                                alt={"ReportSystem"}
                                src={require("../Images/Reports2.png")}
                                className={"w-1/2 h-1/2 rounded-lg"}
                            />
                        </div>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Watchlist system</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>The watchlist system is used to keep an eye on players who are
                                    suspected of cheating, in need of more attention, or have a history of
                                    rule-breaking.
                                </li>
                                <li className={"mb-4"}>Online players on the watchlist can be seen in the BFACP.</li>
                                <li className={"mb-4"}>When these players join and leave the server, a notification is
                                    sent in the #ingame-log channel on Discord.
                                </li>
                            </ol>
                        </p>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Disperse system</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>The disperse system bypasses all the balancer's exceptions and
                                    makes sure that players in the list are always on the opposite team.
                                </li>
                                <li className={"mb-4"}>It should be used for players or player groups that
                                    unbalance/stack rounds and as a result of that make players leave the server. This
                                    usually happens outside of the peak hours.
                                </li>
                                <li className={"mb-4 text-red-500"}>Always warn the player or the group before adding
                                    them to the disperse list!
                                </li>
                                <li className={"mb-4"}>You can see if the player is in the disperse list in the BFACP
                                    under the player's profile.
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-8"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Punishment order</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full font-sofia-pro">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Punishment</th>
                                <th className="px-6 py-3 text-left">Command</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">1. Warning</td>
                                <td className="px-6 py-4">/warn &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">2. Kill</td>
                                <td className="px-6 py-4">/kill &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">3. Kill</td>
                                <td className="px-6 py-4">/kill &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">4. Kick</td>
                                <td className="px-6 py-4">/kick &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">5. 3 Days Temporary Ban</td>
                                <td className="px-6 py-4">/tban 3d &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">6. 14 Days Temporary Ban</td>
                                <td className="px-6 py-4">/tban 14d &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">7. 2 Months Temporary Ban</td>
                                <td className="px-6 py-4">/tban 2m &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">8. 6 Months Temporary Ban</td>
                                <td className="px-6 py-4">/tban 6m &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">9. Permanent Ban</td>
                                <td className="px-6 py-4">/pban &lt;player&gt; &lt;reason&gt;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                <section id={"section-9"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Language punishment
                        order</h2>

                    <table className="min-w-full font-sofia-pro mb-8">
                        <thead>
                        <tr className="bg-zinc-700 text-white">
                            <th className="px-6 py-3 text-left">Punishment</th>
                            <th className="px-6 py-3 text-left">Command</th>
                            <th className="px-6 py-3 text-left w-1/2">Note</th>
                        </tr>
                        </thead>
                        <tbody className="text-gray-100">
                        <tr>
                            <td className="px-6 py-4">1. Warning</td>
                            <td className="px-6 py-4">/warn &lt;player&gt; &lt;reason&gt;</td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4">2. Round Mute</td>
                            <td className="px-6 py-4">/mute &lt;player&gt; &lt;reason&gt;</td>
                            <td className="px-6 py-4">Wait for the round to be over before continuing.</td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4">3. 3 Days Mute</td>
                            <td className="px-6 py-4">/pmute 3d &lt;player&gt; &lt;reason&gt;</td>
                            <td className="px-6 py-4">Can be turned to 3 days ban after 3 mute kicks if severe. Only
                                move to next step if the mute has run out!
                            </td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4">4. 14 Days Mute</td>
                            <td className="px-6 py-4">/pmute 14d &lt;player&gt; &lt;reason&gt;</td>
                            <td className="px-6 py-4">Can be turned to 14 days ban after 3 mute kicks if severe.
                                Only move to next
                                step if the mute has run out!
                            </td>
                        </tr>
                        <tr>
                            <td className="px-6 py-4">5. Permanent Mute</td>
                            <td className="px-6 py-4">/pmute perm &lt;player&gt; &lt;reason&gt;</td>
                            <td className="px-6 py-4">Can be turned to permanent ban after 3 mute kicks if severe.
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4 text-gray-100 font-sofia-pro"}>Players shouldn't be banned for
                                    language
                                    offenses.
                                </li>
                                <li className={"mb-4 text-gray-100 font-sofia-pro"}>This order also applies for
                                    (hack)
                                    accusing
                                    in the chat.
                                </li>
                                <li className={"mb-4 text-gray-100 font-sofia-pro"}>The line between light and
                                    severe
                                    toxicity
                                    lies around how much the player has been toxic in the past and how much of an
                                    insult
                                    the
                                    player used against other player.
                                </li>
                                <li className={"mb-4 text-gray-100 font-sofia-pro"}>Whether the player has ever been
                                    nice
                                    towards other players or is toxic everytime should also be considered!
                                </li>
                                <li className={"mb-4 text-gray-100 font-sofia-pro"}>It is advised to do a little bit
                                    of
                                    research
                                    on the player (chatlogs) when in doubt.
                                </li>
                                <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Light
                                    cases</h3>
                                <p className={"text-base text-gray-100 font-sofia-pro"}>
                                    <ol className={"list-disc ml-6"}>
                                        <li className={"mb-4"}>With light cases the entire order below applies.</li>
                                        <li className={"mb-4"}>Light cases usually revolve around use of one single
                                            word
                                            with no
                                            bad intentions.
                                        </li>
                                    </ol>
                                </p>
                                <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Severe
                                    cases</h3>
                                <p className={"text-base text-gray-100 font-sofia-pro"}>
                                    <ol className={"list-disc ml-6"}>
                                        <li className={"mb-4 text-red-500"}>With severe cases first 3 steps in the
                                            order
                                            have to
                                            be skipped!
                                        </li>
                                        <li className={"mb-4"}>Severe cases usually consist of multiple hateful
                                            words
                                            with bad
                                            intentions.
                                        </li>
                                        <li className={"text-red-500 font-sofia-pro"}> If the player is kicked 3
                                            times
                                            while
                                            muted, their current mute can be converted into a ban of the same
                                            length!
                                        </li>
                                    </ol>
                                </p>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-10"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Punishment exceptions</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>These exceptions are for specific rules and situations that
                                    require a <p className="text-red-500 inline">different approach</p>.
                                </li>
                                <li className={"mb-4"}>Simply follow the special order written in each category. Eg: No
                                    warning, kill, kick and straight to 14 days ban with chat bigotry.
                                </li>
                            </ol>
                        </p>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Glitching</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-decimal ml-6"}>
                                <li className={"mb-4"}>Warning</li>
                                <li className={"mb-4"}>Kick</li>
                                <li className={"mb-4"}>14 Days Temporary Ban</li>
                                <li className={"mb-4"}>Permanent Ban</li>
                            </ol>

                            <p className={"text-gray-300 mt-4 font-cubano text-2xl mb-4"}>What falls under glitching?</p>
                            <ol className={"list-disc ml-12"}>
                                <li className={"mb-4"}>Abusing a vehicle with C4 to reach an otherwise unreachable
                                    position.
                                </li>
                                <li className={"mb-4"}>Merging into rocks or walls.</li>
                                <li className={"mb-4"}>Abusing a MAV to reach a spot that cannot be reached by
                                    jumping.
                                </li>
                                <li className={"mb-4"}>3rd and 4th floor on Metro are not allowed.</li>
                                <li className={"mb-4"}>Glitching passive rockets.</li>
                                <li className={"mb-4"}>PunkBuster banned spots if the PunkBuster fails to ban.</li>
                            </ol>
                        </p>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Abusing /report command</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-decimal ml-6"}>
                                <li className={"mb-4"}>Warning</li>
                                <li className={"mb-4"}>Kick</li>
                                <li className={"mb-4"}>3 Days Temporary Ban</li>
                                <li className={"mb-4"}>14 Days Temporary Ban</li>
                                <li className={"mb-4"}>Permanent Report Blacklist</li>
                            </ol>
                        </p>
                    </div>

                    <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Tags, Emblems and
                        Nicknames</h3>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-decimal ml-6 mt-4"}>
                                <li className={"mb-4"}>Warning (The player has time till the end of the round to
                                    change the tag, emblem or nickname)
                                </li>
                                <li className={"mb-4"}>14 Days Temporary Ban</li>
                                <li className={"mb-4"}>Permanent Ban</li>
                            </ol>

                            <p className={"text-gray-300 mt-4 font-cubano text-2xl mb-4"}>What falls under this
                                category?</p>
                            <ol className={"list-disc ml-12"}>
                                <li className={"mb-4 text-gray-100"}>Homophobia, death threats, Ableism, Racism, Nazism,
                                    Sexism, Xenophobia fall under this category.
                                </li>
                                <li className={"mb-4 text-red-500"}>It is advised to refer to the #banned-emblems
                                    channel on Discord for the list of banned emblems!
                                </li>
                            </ol>

                            <p className={"text-gray-300 mt-4 font-cubano text-2xl mb-4"}>Exceptions</p>
                            <ol className={"list-disc ml-12"}>
                                <li className={"mb-4"}>The following tags are banned automatically and end up in a
                                    permanent ban: AZOV, Z, SS, 1488, NGR, WGNR.
                                </li>
                                <li className={"mb-4"}>If any nickname or emblem resembles the above tags, the
                                    player must be <p className={"text-red-500 inline"}>permanently</p> banned.
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-11"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Commands</h2>
                    <div className={"pl-2"}>
                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Communication Commands</h3>
                        <table className="min-w-full font-sofia-pro">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Command</th>
                                <th className="px-6 py-3 text-left">Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">/say &lt;message&gt;</td>
                                <td className="px-6 py-4">Sends a message to chat visible to everyone.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/psay &lt;player&gt; &lt;message&gt;</td>
                                <td className="px-6 py-4">Same as above but to one specific player.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/yell &lt;message&gt;</td>
                                <td className="px-6 py-4">Displays a server-wide message to all players.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/pyell &lt;player&gt; &lt;message&gt;</td>
                                <td className="px-6 py-4">Same as above but to one specific player.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/tell &lt;message&gt;</td>
                                <td className="px-6 py-4">Does the combination of say and yell command.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/ptell &lt;player&gt; &lt;message&gt;</td>
                                <td className="px-6 py-4">Same as above but to one specific player.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/a &lt;message&gt;</td>
                                <td className="px-6 py-4">Sends a message to all online admins in the server.</td>
                            </tr>
                            </tbody>
                        </table>

                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Infraction system
                            commands</h3>
                        <table className="min-w-full font-sofia-pro">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Command</th>
                                <th className="px-6 py-3 text-left">Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">/punish &lt;playername&gt; &lt;reason&gt;</td>
                                <td className="px-6 py-4">Issues action on the player according to the order of the
                                    punishments, increasing their infraction points.
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/forgive &lt;playername&gt; &lt;reason&gt;</td>
                                <td className="px-6 py-4">Removes one infraction point from the specified player,
                                    resulting in a weaker punishment after being punished again.
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Language system
                            commands</h3>
                        <table className="min-w-full font-sofia-pro">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Command</th>
                                <th className="px-6 py-3 text-left">Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">/lpunish  &lt;playername&gt; &lt;reason&gt;</td>
                                <td className="px-6 py-4">Issues language punishment on the player according to the
                                    order of the
                                    language punishments by one point.
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/lreset &lt;playername&gt; &lt;reason&gt;</td>
                                <td className="px-6 py-4">Resets all points and sets the the player to a clean state
                                    allowing the player to type in the chat.
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Action commands</h3>
                        <table className="min-w-full font-sofia-pro">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Command</th>
                                <th className="px-6 py-3 text-left">Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">/mark &lt;player&gt;</td>
                                <td className="px-6 py-4">Notifies all the admins when the player leaves.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/deq &lt;player&gt;</td>
                                <td className="px-6 py-4">Cancels all queued actions on the targeted player.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/disperse perm &lt;player&gt;</td>
                                <td className="px-6 py-4">Adds the player into the disperse list to ensure they play
                                    against players in the list.
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/punmute &lt;playername&gt;</td>
                                <td className="px-6 py-4">Removes temporary mute on the specified player.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/unmute &lt;playername&gt;</td>
                                <td className="px-6 py-4">Removes round-mute on the specified player.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/watch perm &lt;playername&gt; &lt;reason&gt;</td>
                                <td className="px-6 py-4">Adds the player permanently into the watchlist.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/rwatch &lt;playername&gt;</td>
                                <td className="px-6 py-4">Removes the player from the watchlist.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/pwhitelist &lt;duration&gt; &lt;playername&gt;</td>
                                <td className="px-6 py-4">Whitelists the player from ping kicks for the specified
                                    duration.
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/rblacklist perm &lt;playername&gt; &lt;reason&gt;</td>
                                <td className="px-6 py-4">Adds the player permanently to the report blacklist.
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/unrblacklist &lt;playername&gt; &lt;reason&gt;</td>
                                <td className="px-6 py-4">Removes the player from the report blacklist.
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Report handling
                            commands</h3>
                        <table className="min-w-full font-sofia-pro">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Command</th>
                                <th className="px-6 py-3 text-left">Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">/accept &lt;report id&gt;</td>
                                <td className="px-6 py-4">Accepts the report.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/deny &lt;report id&gt;</td>
                                <td className="px-6 py-4">Denies the report.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/ignore &lt;report id&gt;</td>
                                <td className="px-6 py-4">Ignores the report.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/reportlist</td>
                                <td className="px-6 py-4">Command used to see latest 6 unanswered round reports.</td>
                            </tr>
                            </tbody>
                        </table>

                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Information Commands</h3>
                        <table className="min-w-full font-sofia-pro">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Command</th>
                                <th className="px-6 py-3 text-left">Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">/rules &lt;player&gt;</td>
                                <td className="px-6 py-4">Sends rules to the selected player.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/rep &lt;player&gt;</td>
                                <td className="px-6 py-4">Sends the player's reputation to you.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/pinfo &lt;player&gt;</td>
                                <td className="px-6 py-4">Provides extensive information about the player.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/pchat &lt;player&gt;</td>
                                <td className="px-6 py-4">Fetches the player's chat history.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/find &lt;player&gt;</td>
                                <td className="px-6 py-4">Shows the player's current team and squad.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/help</td>
                                <td className="px-6 py-4">Lists the commands available for use.</td>
                            </tr>
                            </tbody>

                        </table>

                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Universal Commands</h3>
                        <table className="min-w-full font-sofia-pro">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Command</th>
                                <th className="px-6 py-3 text-left">Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">/yes</td>
                                <td className="px-6 py-4">Confirms your action.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/no</td>
                                <td className="px-6 py-4">Declines your action.</td>
                            </tr>
                            </tbody>
                        </table>

                        <h3 className="text-3xl lg:text-4xl text-amber-500 font-cubano my-6">Special Commands</h3>
                        <p className={"text-red-500 font-bold text-2xl mb-4"}>Use these with caution!</p>

                        <p className="text-gray-300 mt-4 font-cubano text-2xl mb-4">Nuke usage scenarios on vehicle
                            maps</p>
                        <p className="text-base text-gray-100 font-sofia-pro">
                            <ol className="list-disc ml-12">
                                <li className="mb-4 underline">Maps with multiple vehicles and 5 or more flags.</li>
                                <li className="mb-4">If the winning team controls all flags, has more tickets (and is
                                    baseraping).
                                </li>
                                <li className="mb-4">If a team is dominating the game, has a significant advantage in
                                    tickets and flags (but not a full cap), and the other team doesn’t seem to be able
                                    to fight back.
                                </li>
                            </ol>
                            <div
                                className="bg-zinc-900 border-l-4 border-amber-400 text-amber-400 p-4 shadow-md rounded-lg flex items-center space-x-3 ml-6">
                                <svg className="w-[24px] h-[24px] text-amber-400 flex-shrink-0"
                                     xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M12 8v4m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"/>
                                </svg>
                                <p className="font-bold">In both cases, you should issue a warning about the incoming
                                    nuke
                                    using /tell at least once and after at least 15 seconds you must nuke the winning
                                    team (unless the situation on the Battlefield changes unexpectedly).</p>
                            </div>

                        </p>

                        <p className={"text-gray-300 mt-4 font-cubano text-2xl mb-4"}>Nuke Deployment on Infantry
                            maps</p>
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-12"}>
                                <li className={"mb-4 underline"}>Maps with no vehicles or only transport vehicles.</li>
                                <li className={"mb-4"}>Losing team holds no flags and has fewer tickets.</li>
                            </ol>
                            <div
                                className="bg-zinc-900 border-l-4 border-amber-400 text-amber-400 p-4 shadow-md rounded-lg flex items-center space-x-3 ml-6">
                                <svg className="w-[24px] h-[24px] text-amber-400 flex-shrink-0"
                                     xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M12 8v4m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"/>
                                </svg>
                                <p className="font-bold">In this case you must nuke the winning
                                    team, issuing a warning is optional on infantry maps.</p>
                            </div>

                        </p>

                        <table className="min-w-full font-sofia-pro mt-8">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Command</th>
                                <th className="px-6 py-3 text-left">Description</th>
                                <th className="px-6 py-3 text-left">Condition for use</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">/wnuke</td>
                                <td className="px-6 py-4">Kills the winning team.</td>
                                <td className="text-red-500 px-6 py-4">Both teams have at least 200 tickets left.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/nuke &lt;US/RU/CN&gt;</td>
                                <td className="px-6 py-4">Initiates a nuke for the specified team.</td>
                                <td className="text-red-500 px-6 py-4">Both teams have at least 200 tickets left.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">/unban &lt;name&gt;</td>
                                <td className="px-6 py-4">Unbans the specified player.</td>
                                <td className="text-red-500 px-6 py-4">Player has successfully appealed a ban or when
                                    adjusting a punishment to align with the ban policy.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                {/*
                <section id={"section-12"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Miscellaneous</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}>
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>
                */}

                <section id={"section-12"} className="mb-8">
                    <h2 className="text-3xl lg:text-5xl text-emerald-500 font-cubano mb-4">Useful Tools</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-4"}><a href="https://www.bf4cheatreport.com/"
                                                          className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600">BF4CR</a>
                                </li>
                                <li className={"mb-4"}><a href="https://bf4db.com"
                                                          className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600">BF4DB</a>
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>
            </div>

            <div className="text-center mt-8">
                <h4 className="text-3xl lg:text-5xl text-green-500 font-cubano">Ready to apply and join our team?</h4>
                <a href="https://discord.bf4c.net"
                   className="inline-flex items-center bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500 text-xl text-white font-attribute-mono font-bold p-2 rounded mt-4 transition-transform hover:scale-105">
                    <img src={require("../Images/discord-white-icon.png")} alt={"Discord"} className="h-10 w-10 mr-4"/>
                    Apply on our Discord server!
                </a>
            </div>
        </div>
    );
};

export default InGameAdminGuide;
