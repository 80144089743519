import React, {useEffect, useState, useRef} from "react";

const BanPolicy = () => {
    const sectionRefs = useRef({});
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    }

    const banPolicyParts = [
        'cheating',
        'glitching',
        'severe-toxicity',
        'stream-sniping',
        'report-abuse',
        'blatant-accusing',
        'farm-ban',
        'BF4DB/BA-whitelist',
        'ban-evasion',
        'nickname-spoofing',
        'staff-decisions',
        'other'
    ];

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 200;
            const sections = Object.values(sectionRefs.current);

            for (let i = sections.length - 1; i >= 0; i--) {
                const section = sections[i];
                if (scrollPosition >= section.offsetTop) {
                    break;
                }
            }
        };

        const handleHashScroll = () => {
            if (window.location.hash) {
                const hash = window.location.hash.substring(1); // Remove '#' from hash
                const section = document.getElementById(hash);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleHashScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToSection = (index) => {
        const sectionId = banPolicyParts[index];
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            window.history.pushState(null, null, `#${sectionId}`);
        }
    }

    return (
        <div className={"bg-gradient-to-b from-zinc-900 to-zinc-700 min-h-screen p-4"}>
            <div className="max-w-screen-lg mx-auto p-4">
                <section className="mb-8">
                    <h2 className="text-5xl text-emerald-600 font-cubano mb-4">Ban Policy</h2>
                    <p className={"text-lg text-gray-100 font-sofia-pro mb-2"}>
                        This ban policy is tied to our PC in-game <a href="/servers"
                                                                     className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600">servers</a>.
                        Its purpose is to inform the reader if they are applicable for their ban to be lifted and under
                        what circumstances.
                    </p>
                    <img
                        src={require("../Images/OperationOutbreakSoldiers.png")}
                        alt={"OperationOutbreak"}
                        className={"w-full h-auto rounded-lg shadow-lg"}
                    />
                </section>

                <div className="mb-8">
                    <button
                        className={`w-full text-left p-4 bg-zinc-700 0 flex justify-between items-center ${isOpen ? 'rounded-t-xl' : 'rounded-xl'}`}
                        onClick={toggleAccordion}>
                        <h1 className={"text-center text-2xl lg:text-3xl lg:text-left text-amber-600 font-cubano"}>Sections
                            of the ban policy</h1>
                    </button>
                    {isOpen && (
                        <div className="p-4 bg-zinc-800">
                            <ul className="list-disc list-inside text-left">
                                {banPolicyParts.map((value, index) => (
                                    <li className="text-white text-lg font-semibold cursor-pointer"
                                        onClick={() => scrollToSection(index)}>
                                        {value.split("-")[0].charAt(0).toUpperCase() + value.split("-")[0].slice(1)} {value.split("-")[1]}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                <section id="cheating" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Cheating</h2>
                    <p className={"text lg text-orange-600 font-sofia-pro font-bold mb-2"}>This ban is partially
                        appealable.</p>
                    <p className={"text-base text-gray-100 font-sofia-pro"}>
                        To receive an unban for cheating, we require a recording of the player meeting the stats
                        requirements they have been banned for.
                        Understand that the bans that have no video evidence will not be lifted. Otherwise, the player
                        is given 3 days unban on our servers and must record their gameplay only on the specific server
                        they have been banned on.
                        The recording must not be edited in any way and should at least be in 1080p. Failing to meet
                        these requirements will result in a permanent ban.
                    </p>
                </section>

                <section id="glitching" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Glitching</h2>
                    <p className="text-lg text-red-600 font-sofia-pro font-bold mb-2">
                        This ban is not appealable.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            Glitching results in a 14-day ban and is permanent on repeat.
                            The following glitches are not allowed:
                        </p>
                        <ul className="list-disc ml-6 mt-4">
                            <li>Abusing a vehicle with C4 to reach an otherwise unreachable position (positions that
                                cannot be reached using air vehicles).
                            </li>
                            <li>Merging into rocks or walls.</li>
                            <li>Abusing a MAV to reach a spot that cannot be reached through jumping.</li>
                            <li>3rd and 4th floor on Metro is not allowed.</li>
                            <li>Glitching passive rockets.</li>
                            <li>PunkBuster banned spots if the PunkBuster fails to ban.</li>
                        </ul>
                    </div>
                </section>

                <section id="severe-toxicity" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Severe toxicity</h2>
                    <p className="text-lg text-red-600 font-sofia-pro font-bold mb-2">
                        This ban/mute is not appealable.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            Player nicknames, emblems and tags under severe toxicity results in a 14-day ban without prior warning. A repeated offense results in a permanent ban. Chat messages that fall under the severe toxicity umbrella should be punished with a 14-day mute first, followed by a permanent mute if the player repeats the offence after the first punishment is over. If the player is kicked 3 times while muted, their current mute can be converted into a ban of the same length!
                            The following terms are considered severe toxicity:
                        </p>
                        <ul className="list-disc ml-6 mt-4 mb-4">
                            <li>Homophobia</li>
                            <li>Death threats</li>Z
                            <li>Ableism</li>
                            <li>Racism</li>
                            <li>Nazism</li>
                            <li>Sexism</li>
                            <li>Politics</li>
                            <li>Xenophobia</li>
                        </ul>
                        <p className="text-base text-gray-100 font-sofia-pro italic">
                            Players that have their emblem(s) associated with clantags that are banned on our server are
                            punished by a permanent ban.<br/>
                            Currently banned clantags with variations are:
                        </p>
                        <ul className="list-disc ml-6 mt-4 mb-4">
                            <li>AZOV</li>
                            <li>Z</li>
                            <li>SS</li>
                            <li>1488</li>
                            <li>WGNR</li>
                            <li>NGR</li>
                        </ul>
                    </div>
                </section>

                <section id="stream-sniping" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Stream sniping</h2>
                    <p className={"text lg text-orange-600 font-sofia-pro font-bold mb-2"}>This ban is partially
                        appealable.</p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            Ban received for stream sniping ends in a permanent ban. In order to appeal this type of
                            ban, there must not be a piece of video evidence from the streamer's perspective containing
                            apparent stream sniping.
                        </p>
                    </div>
                </section>

                <section id="report-abuse" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Abuse of the /report command</h2>
                    <p className="text-lg text-green-600 font-sofia-pro font-bold mb-2">
                        This ban is appealable once, but the permanent blacklist is not.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            Abusing the /report command with false reports that are not in accordance with our rules
                            ends in a 3-day ban first, then with 14-day ban, and finally permanent report blacklist.
                        </p>
                    </div>
                </section>

                <section id="blatant-accusing" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Blatant (hack)accusing in the chat</h2>
                    <p className="text-lg text-green-600 font-sofia-pro font-bold mb-2">
                        This mute is appealable once, but permanent mute is not.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            Blatant accusing of other players without any evidence ends in 3-day mute first, then 14-day mute, and finally permanent mute.
                        </p>
                    </div>
                </section>

                <section id="farm-ban" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Killing server's population / Farming
                        ban</h2>
                    <p className="text-lg text-red-600 font-sofia-pro font-bold mb-2">
                        This ban is not appealable.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            To keep the server filled, this type of ban cannot be appealed. Ban length can vary, but can
                            be a maximum of 1 day if banned because of the rule. To get more information about the farm
                            limit,
                            you can use !farmlimit command on the servers or read the information on the round start in
                            the chat.
                            This ban may end in a permanent ban if the player is caught doing it multiple times and has
                            violated
                            other rules.
                        </p>
                    </div>
                </section>

                <section id="whitelist" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">BF4DB/BA whitelist</h2>
                    <p className={"text lg text-orange-600 font-sofia-pro font-bold mb-2"}>Whitelist is partially
                        possible.</p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            A whitelist from one of the anti-cheat parties can be received as long as the admin team
                            disagrees with the ban. Bans that don't contain strong evidence such as video or screenshot,
                            might be whitelisted.
                        </p>
                    </div>
                </section>

                <section id="ban-evasion" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Ban evasion</h2>
                    <p className="text-lg text-red-600 font-sofia-pro font-bold mb-2">
                        This ban is not appealable.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            Ban evasion is absolutely not tolerated and ends in a permanent ban across all the linked
                            accounts. Future accounts might be banned too if there is enough evidence suggesting the
                            account is linked.
                        </p>
                    </div>
                </section>

                <section id="nickname-spoofing" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Nickname spoofing</h2>
                    <p className="text-lg text-red-600 font-sofia-pro font-bold mb-2">
                        This ban is not appealable.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            Nickname spoofing is not allowed and ends in a permanent ban. Players that get caught
                            having a nickname spoofed cannot appeal their ban.
                        </p>
                    </div>
                </section>

                <section id="staff-decisions" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Staff Decisions</h2>
                    <p className="text-lg text-red-600 font-sofia-pro font-bold mb-2">
                        This ban is not appealable.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            In extreme cases, staff team may handle a voting to permanently ban a player or group of
                            players that violated multiples rules over a larger time span. Staff decisions are rare, but
                            are carefully considered and discussed.
                        </p>
                    </div>
                </section>

                <section id="other" className="mb-8">
                    <h2 className="text-4xl text-amber-600 font-cubano mb-4">Other rules</h2>
                    <p className="text-lg text-green-600 font-sofia-pro font-bold mb-2">
                        This ban is appealable once and only if it's the first ban and not a permanent one.
                    </p>
                    <div className="text-base text-gray-100 font-sofia-pro">
                        <p>
                            This category applies to any ban received that is not listed above. If the player is known to be
                            a rule-breaker that violated multiple rules despite all the warnings, they are not eligible for an unban.
                            Bans that seem to not match any of the rules will get lifted. In such case, it is advised to open an admin abuse
                            ticket on our Discord server.
                        </p>
                    </div>
                </section>

                <div className="mb-8 text-center">
                    <a href="https://discord.bf4c.net/"
                       className="inline-flex items-center justify-center px-4 py-2 text-2xl font-mono font-bold bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500 text-white rounded transition-transform hover:scale-105">
                        <img src={require("../Images/discord-white-icon.png")} alt="Discord Logo"
                             className="h-10 w-10 mr-4"/>
                        Join us for support!
                    </a>
                </div>
            </div>
        </div>
    )
}

export default BanPolicy;
